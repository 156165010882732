import React from 'react'
import {useParams} from 'react-router-dom'
import './StreamShow.css'

export const StreamShow = () => {
    const {id} = useParams()
  return (
    <div className="download__section" >
        <div className="download__title">Watch Online</div>
        <div className='streamer'>
            <iframe title={id+id} src={`https://vidsrc.to/embed/movie/${id}`} width={window.screen.width - 300} height={window.screen.height -450}></iframe>
        </div>
    </div>
  )
}
