import React from 'react'
import './Header.css'
import {GiFilmProjector} from 'react-icons/gi'
import {FiSearch} from 'react-icons/fi'

function Header() {
  return (
    <>
    <header>
        <div className="logo">
          <div>
            <GiFilmProjector />
          </div>
          <div>
            CELLULOID.IN
          </div>
        </div>
        <div className="search">
          <FiSearch/>
        </div>
    </header>
    </>
  )
}

export default Header