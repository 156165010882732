import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './Pages/Home'
import Movie from './Pages/Movie'
import Download from './Pages/Download'
import Season from './Pages/Season'
  import EpisodeSelection from './Components/EpisodeSelection/EpisodeSelection'

import './App.css'

function App() {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/:show/:id" element={<Movie  />} />
          <Route path="/:show/:id/download" element={<Download />} />
          <Route path="/:show/:id/seasons" element={<Season  />} /> 
          <Route path="/:show/:id/seasons/:season_id/episode" element={<EpisodeSelection  />} />
        </Routes>
      </Router>
  )
}

export default App