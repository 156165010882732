import React from "react";
import { useEffect, useState } from "react";
import "./SeasonSelection.css";
import {
  tmdb_GetSeries,
  tmdb_GetSeriesImdbId,
} from "../../Constants/urls";
import { tmdb_ImageUrl } from "../../Constants/Constants";
import axios from "../../axios";
import { useParams ,useNavigate } from "react-router-dom";
import {
  getCorrectOrderedDate,
  getMovieTitle,
} from "../CommenMovieDetails";
import ISO6391 from "iso-639-1";

function SeasonSelection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [series, setSeries] = useState();
  const [seasons, setSeasons] = useState([])

  useEffect(() => {
    axios.get(tmdb_GetSeries(id)).then((response) => {
      setSeries(response.data);
      setSeasons(response.data.seasons)

      axios.get(tmdb_GetSeriesImdbId(id)).then((response) => {
        const imdb = response.data.imdb_id;
        const trimmedImdb = imdb.replace("tt", "");
        console.log(trimmedImdb);
      });
    });
  }, [id]);

  return (
    <div>
      {console.log(series)}
      <div
        className="download__wrapper"
        style={{
          backgroundImage: `URL(${
            series ? tmdb_ImageUrl + series.backdrop_path : ""
          })`,
        }}
      ></div>
      <div className="download__container">
        <div className="download__poster">
          <img src={series ? tmdb_ImageUrl + series.poster_path : ""} alt="" />
        </div>
        <div className="download__desc">
          <div>{series ? getMovieTitle(series) : "Na"}</div>
          <div>{series ? ISO6391.getName(series.original_language) : "NA"}</div>
          <div>
            {series ? Number(series.vote_average.toFixed(1)) + "/10" : "NA"}
          </div>
          <div>{series ? getCorrectOrderedDate(series) : "Na"}</div>
        </div>
      </div>
      <div className="download__section">
        <div className="download__title">Season Section</div>
        <div className="season__selector">
          {seasons.map((season,index)=>{
            return(
              <div className="season__card" key={index} onClick={()=>navigate(season.season_number+'/episode')}>
                <img src={season? tmdb_ImageUrl + season.poster_path : ''} alt="" />
                <div className="season__details">
                  <div>{season.name}</div>
                  <div>Total Episodes : {season.episode_count}</div>
                  <div>Avarage Voting : {season.vote_average}</div>
                  <div>Plot : {season.overview}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default SeasonSelection;
