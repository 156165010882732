import React from 'react'
import Header from '../Components/Header/Header'
import MovieDetails from '../Components/MovieDetails/MovieDetails'


function Movie() {
  return (
    <div>
      <Header />
      <MovieDetails />
    </div>
  )
}

export default Movie