import React, { useEffect, useState } from "react";
import "./HomeSlider.css";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";
import {
  EffectCoverflow,
  Autoplay,
  Parallax,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper/modules";
import ISO6391 from "iso-639-1";
import { Link } from "react-router-dom";
// axios
import axios from "../../axios";
import { tmdb_trending } from "../../Constants/urls";
import { tmdb_ImageUrl } from "../../Constants/Constants";

function HomeSlider() {
  const [slider, setSlider] = useState([]);
  const [swiper, setSwiper] = useState(5);

  useEffect(() => {
    axios.get(tmdb_trending).then((response) => {
      setSlider(response.data.results);
    });
  },[]);

  const checkShow = (obj)=>{
    if(obj.media_type === 'movie'){
        return 'movie'
    }else{
        return 'tv'
    }
  }

  return (
    <div className="banner">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        speed={600}
        data-swiper-parallax="-23%"
        parallax={true}
        navigation={false}
        autoplay={false}
        allowTouchMove={false}
        onSwiper={(swiper) => setSwiper(swiper)}
        pagination={false}
        modules={[EffectFade, Parallax]}
        className="banner_cover"    
      >
        {slider.map((obj) => {
          
          return (
            <SwiperSlide key={obj.id}>
              <div className="slider_desc">
                <div className="title" data-swiper-parallax="-300">
                  {obj.title ? obj.title : obj.name}
                </div>
                <div className="subtitle" data-swiper-parallax="-200">
                  <div>
                    {obj.release_date
                      ? new Date(obj.release_date).getFullYear()
                      : new Date(obj.first_air_date).getFullYear()}
                  </div>
                  <div>
                    {obj ? Number(obj.vote_average).toFixed(1) + "/10" : "NA"}
                  </div>
                  <div>
                    {obj ? ISO6391.getName(obj.original_language) : "NA"}
                  </div>
                </div>
                <div className="button_container" data-swiper-parallax="-100">
                  <Link className="btn success-outline">Play Trailer</Link>
                  <Link
                    className="btn danger-outline download"
                    to={`/${checkShow(obj)}/${obj.id}`}
                  >
                    Download
                  </Link>
                </div>
                <div className="button_container" data-swiper-parallax="-50">
                  <p>{obj ? obj.overview : "NA"}</p>
                </div>
              </div>
              <div
                className="banner__cover"
                style={{
                  backgroundImage: `url(${
                    slider ? tmdb_ImageUrl + obj.backdrop_path : ""
                  })`,
                }}
              ></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="banner__container">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 300,
            modifier: 3.5,
            slideShadows: true,
          }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          onActiveIndexChange={(obj) => {
            swiper.slideTo(obj.activeIndex);
          }}
          lazyPreloadPrevNext={3}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
            clickable: true,
          }}
          loop={false}
          pagination={{ el: ".pagination", clickable: true }}
          modules={[EffectCoverflow, Autoplay, Navigation, Pagination]}
          className="slide_container"
        >
          {slider.map((obj) => {
            return (
              <SwiperSlide key={obj.id}>
                <img
                  alt="hello"
                  src={slider ? tmdb_ImageUrl + obj.poster_path : ""}
                />
              </SwiperSlide>
            );
          })}

          <div className="slider-controler">
            <div className="button-prev">
              <BsArrowLeftCircle />
            </div>
            <div className="button-next">
              <BsArrowRightCircle />
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default HomeSlider;
