import React from 'react'
import Header from '../Components/Header/Header'
import SeasonSelection from '../Components/SeasonSelection/SeasonSelection'



function Season() {
  return (
    <>
        <Header />
        <SeasonSelection />
    </>
  )
}

export default Season