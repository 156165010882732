import {tmdb_API_KEY} from './Constants'



// ================== TMDB URLS ==========================



    export const tmdb_trending = `trending/all/week?api_key=${tmdb_API_KEY}&language=en-US`
    export const tmdb_GetMovie_test = `find/tt11992424?external_source=imdb_id&api_key=${tmdb_API_KEY}`
    export const tmdb_GetMovie_by_IMDB = `?external_source=imdb_id&api_key=${tmdb_API_KEY}`

    // return movie details using id 
    export function tmdb_GetMovie (id){
        return `movie/${id}?api_key=${tmdb_API_KEY}`
    }
    // return series details using id
    export function tmdb_GetSeries (id){
        return `tv/${id}?api_key=${tmdb_API_KEY}`
    }

    // Returns movie credits using id 
    export function tmdb_GetMovieCredits (id){
        return `movie/${id}/credits?api_key=${tmdb_API_KEY}`
    }
    // Returns series credits using id 
    export function tmdb_GetSeriesCredits (id){
        return `tv/${id}/credits?api_key=${tmdb_API_KEY}`
    }

    // Returns imdb id of Series by tmdb id
    export function tmdb_GetSeriesImdbId (id){
        return `tv/${id}/external_ids?api_key=${tmdb_API_KEY}`
    }



// =================== YTS URLS =====================

    // Returns movie all detais using imdb id

    export const yts_GetMovieLinks = (id)=>{
        return 'movie_details.json?imdb_id='+id;
    }



// ================== EZTV URLS ===================

    // Returns episodes and show details using imdb id

    export const eztv_GetSeriesLinks = (id,page)=>{
        return 'get-torrents?imdb_id='+id+'&limit=100&page='+page;
    }
