import axios from '../../axios'
import axios_eztv from '../../axios_eztv'
import React from 'react'
import {useEffect, useState} from 'react'
import { eztv_GetSeriesLinks, tmdb_GetSeries, tmdb_GetSeriesImdbId } from '../../Constants/urls'
import { useParams } from 'react-router-dom'

function EpisodeSelection() {
    const {id, season_id} = useParams()
    const [episodes, setEpisodes] = useState([])

    const getEpisodeCount = (id, season_id)=>{
        axios.get(tmdb_GetSeries(id)).then((res)=>{
            return res.data
        })
    }

    useEffect(() => {
        axios.get(tmdb_GetSeriesImdbId(id)).then((response) => {
          const imdb = response.data.imdb_id;
          const trimmedImdb = imdb.replace("tt", "");
          console.log(trimmedImdb);
    
          axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,1)).then((resp)=>{
            const torrentsCount = resp.data.torrents_count;
            console.log(torrentsCount)
    
            if(torrentsCount <= 100){
              axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,1)).then((resp)=>{
                setEpisodes(resp.data.torrents);
              })
            }else if(torrentsCount <= 200 && torrentsCount >= 101){
              axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,1)).then((resp)=>{
                setEpisodes(resp.data.torrents);
                  axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,2)).then((resp2)=>{
                    setEpisodes((episodes)=> [...episodes,...resp2.data.torrents]);
                  })
              })
            }else if(torrentsCount <= 300 && torrentsCount >= 201){
              axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,1)).then((resp)=>{
                setEpisodes(resp.data.torrents);
                  axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,2)).then((resp2)=>{
                    setEpisodes((episodes)=> [...episodes,...resp2.data.torrents]);
                      axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,3)).then((resp3)=>{
                        setEpisodes((episodes)=> [...episodes, ...resp3.data.torrents]);
                      })
                  })
              })
            }else if(torrentsCount <= 400 && torrentsCount >= 301){
                axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,1)).then((resp)=>{
                  setEpisodes(resp.data.torrents);
                    axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,2)).then((resp2)=>{
                      setEpisodes((episodes)=> [...episodes,...resp2.data.torrents]);
                        axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,3)).then((resp3)=>{
                          setEpisodes((episodes)=> [...episodes, ...resp3.data.torrents]);
                          axios_eztv.get(eztv_GetSeriesLinks(trimmedImdb,4)).then((resp4)=>{
                          setEpisodes((episodes)=> [...episodes, ...resp4.data.torrents]);
                          })
                        })
                    })
                })
              }

            
        })
         
    });
}, [id])



const seasonisedEpisodes = () =>{
    // return episodes.filter(episodes=> episodes.season === toString(season_id))
    // return episodes.groupBy(episodes, season => episodes.season)
    return Object.groupBy(episodes, season => season.season)
    // return episodes.filter(episodes => episodes.season_id === season_id)
    // return episodes
}

return (
    <div>

        {console.log(seasonisedEpisodes())}
        {/* {console.log(episodes)} */}
        
    </div>
  )
}

export default EpisodeSelection