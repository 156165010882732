import React from "react";
import LoadingGif from '../../Images/Rolling.gif'
import $ from 'jquery'

function DownloadFile(props) {
  const downloadStat = ()=>{
    $('.download__loading').hide()
    console.clear()
  }

    const url = "https://yahska.net/control_apis/torrent.api.php?mag="+props.url;
  return (
    <div>
      <div className="block__loader"></div>
      <div className="download__card">
        <iframe
          src={url}
          title="download"
          width={"600px"}
          height={"410px"}
          onLoad={()=>downloadStat()}
        ></iframe>
        <img src={LoadingGif} alt="Loading..." className="download__loading"/>
      </div>
    </div>
  );
}

export default DownloadFile;
