import React from "react";
import axios from "../../axios";
import "./MovieDetails.css";
import { tmdb_GetMovie, tmdb_GetSeries } from "../../Constants/urls";
import { tmdb_ImageUrl } from "../../Constants/Constants";
import { useState, useEffect} from "react";
import { Link, useParams , useLocation } from "react-router-dom";
import {
  getMovieYear,
  getMovieTitle,
  getCorrectOrderedDate,
  showMovieOrSeries,
  getMovieOrSeriesDownloadButton,
} from "../CommenMovieDetails";
import ISO6391 from "iso-639-1";
import ShowCredits from "../ShowCredits/ShowCredits";

function MovieDetails() {
  const [movie, setMovie] = useState("");
  const { id , show } = useParams();
  const location = useLocation();
  useEffect(() => {
    if(show === 'movie'){
      axios.get(tmdb_GetMovie(id)).then((response) => {
        // console.log(response.data);
        setMovie(response.data);
      });
    }else if(show === 'tv'){
      axios.get(tmdb_GetSeries(id)).then((response) => {
        // console.log(response.data);
        setMovie(response.data);
      });
    }
  }, [id , show]);
  if (!movie) {
    return <div>Loading...</div>;
  }
  return (
    <div className="show__container">
      <div
        className="show__banner"
        style={{
          backgroundImage: `URL(${
            movie ? tmdb_ImageUrl + movie.backdrop_path : ""
          })`,
        }}
      >
        <div className="show__poster">
          <img src={movie ? tmdb_ImageUrl + movie.poster_path : ""} alt="" />
         <Link to={location.pathname+getMovieOrSeriesDownloadButton(show)} className="btn danger-outline text-center mt-3rem">
            Download
          </Link>
          <Link to={location.pathname+"/download"} className="btn success-outline text-center mt-1rem">
            Play Trailer
          </Link>
        </div>
        <div className="show__contents">
          <div className="show__title">{getMovieTitle(movie)}</div>
          <div className="show__sub">
            <div>
              {getMovieYear(movie)}
              {console.log(movie)}
            </div>
            <div>{movie ? ISO6391.getName(movie.original_language) : "NA"}</div>
            <div>
              {movie ? Number(movie.vote_average.toFixed(1)) + "/10" : "NA"}
            </div>
            <div>{getCorrectOrderedDate(movie)}</div>
            <div>{showMovieOrSeries(show)}</div>
          </div>
          <hr />
          <div className="show__credits">
            <ShowCredits id={movie.id} show={movie.media_type} />
          </div>
          <hr className="show__desc__hr" />
          <div className="show__desc">{movie ? movie.overview : "NA"}</div>
        </div>
      </div>
    </div>
  );
}

export default MovieDetails;
