import React from 'react'
import {useState , useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css';
import {Navigation} from 'swiper/modules'
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import './ShowCredits.css'
import $ from 'jquery'
import {FaRegCaretSquareRight,FaRegCaretSquareLeft} from 'react-icons/fa'
// axios 
import axios from '../../axios'
import {tmdb_GetMovieCredits, tmdb_GetSeriesCredits} from '../../Constants/urls'
import {tmdb_ImageUrl} from '../../Constants/Constants'


function ShowCredits() {
    const {id , show} = useParams()
    const [slider, setSlider] = useState([]);

    function showCast(val){
      $('.cast__role').css('padding','1rem')
      $('.cast__role').html(val)
    }

  
    useEffect(() => {
      if(show === 'movie'){
        axios.get(tmdb_GetMovieCredits(id)).then((response)=>{
          setSlider(response.data.cast)
        })
      }else{
        axios.get(tmdb_GetSeriesCredits(id)).then((response)=>{
          setSlider(response.data.cast)
        })
      }
    }, [id , show])

  return (
        <Swiper
        className="cast__container"
        spaceBetween={0}
        grabCursor={true}
        modules={[Navigation]}
        breakpoints={{
              320: {
                slidesPerView: 4,
                spaceBetween: 10
              },
              1140: {
                slidesPerView: 5,
                spaceBetween: 10
              },
              1400: {
                slidesPerView: 6,
                spaceBetween: 10
              },
              1640: {
                slidesPerView: 7,
                spaceBetween: 10
              }
          }}
          navigation={{
            nextEl: '.cast__next',
            prevEl: '.cast__prev',
            clickable: true,
          }}
        lazyPreloadPrevNext={3}
        >
        <div className="cast__role"> </div>
            {slider.map((cast,index)=>{
                return(
                    <SwiperSlide key={cast ? cast.order : index} className='cast__slider' id='cast' onFocus={()=>showCast(cast.name+ ' as '+ cast.character)} onMouseOver={()=>showCast(cast.name+ ' as '+ cast.character)}>
                        <img alt={cast ? cast.name : ''} src={cast.profile_path ? tmdb_ImageUrl+cast.profile_path : ''} className='cast__img' />
                    </SwiperSlide>
                )
            })}

        <div className="cast__controler">
          <div className="cast__next">
            <FaRegCaretSquareLeft />
          </div>
          <div className="cast__prev">
            <FaRegCaretSquareRight />
          </div>
        </div>
        </Swiper>
  )
}



export default ShowCredits