import axios from "../axios";
import { tmdb_GetSeriesImdbId } from "../Constants/urls";

export function getMovieTitle(obj){
    if(obj.title){
      return obj.title;
    }else if(obj.name){
      return obj.name;
    }else{
      return 'Error';
    }
  }

export function getMovieYear(obj){
    if(obj.release_date){
        const year = new Date(obj.release_date)
        return year.getFullYear()
    }else if(obj.first_air_date){
        const year = new Date(obj.first_air_date)
        return year.getFullYear()
    }else{
        return 'Error';
    }
}

export function getCorrectOrderedDate(obj){
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  if(obj.release_date){
    const release = new Date(obj.release_date)
    return release.getDate()+'-'+months[release.getMonth()]+'-'+release.getFullYear()
  }else if(obj.first_air_date){
    const release = new Date(obj.first_air_date)
    return release.getDate()+'-'+months[release.getMonth()]+'-'+release.getFullYear()
}else{
    return 'Error';
}
}
export function getMovieYear2(obj){
      const year = new Date(obj.release_date)
      return year.getFullYear()
}

export function showMovieOrSeries(obj){
  console.log(obj.media_type);
  if (obj === 'movie') {
      return 'Movie'
  } else{
    return 'Series'
  }
}

export function getMovieOrSeriesDownloadButton(show){
  if(show === 'movie'){
    return '/download'
  }else if(show === 'tv'){
    return '/seasons'
  }
}

// Get IMDB ID of any Series by TMDB ID

export async function getImdbIdOfSeries(id) {
    await axios.get(tmdb_GetSeriesImdbId(id)).then((response) => {
    return response.data.imdb_id;
  })
}
