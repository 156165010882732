import React from 'react'
import Header from '../Components/Header/Header'
import DownloadSelection from '../Components/DownloadSelection/DownloadSelection'

function Download() {
  return (
    <>
      <Header />
      <DownloadSelection/>
    </>
  )
}

export default Download