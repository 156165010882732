import React from 'react'
import Header from '../Components/Header/Header'
import { IconContext } from "react-icons";
// import HomeBanner from '../Components/HomeBanner/HomeBanner';
import HomeRowMovies from '../Components/HomeRowMovies/HomeRowMovies';
import HomeSlider from '../Components/HomeBanner/HomeSlider';


function Home() {
  return (
    <IconContext.Provider value={{ className: "base-icons" }}>
      <Header/>
      <HomeSlider/>
      <HomeRowMovies title='New Arrivals' name='new'/>
      <HomeRowMovies title='Action' name='action'/>
      <HomeRowMovies title='Comedy' name='comedy'/>
    </IconContext.Provider>
  )
}

export default Home