import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import axios_yts from "../../axios_yts";
import { tmdb_GetMovie, yts_GetMovieLinks } from "../../Constants/urls";
import { tmdb_ImageUrl } from "../../Constants/Constants";
import "./DownloadSelection.css";
import { getMovieTitle, getCorrectOrderedDate } from "../CommenMovieDetails";
import ISO6391 from "iso-639-1";

// ICONS ====
import { TfiAngleDoubleRight } from "react-icons/tfi";
import { GiMagnet } from "react-icons/gi";
import { PiFileZipFill } from "react-icons/pi";
import { FiDownloadCloud } from "react-icons/fi";
import { magnet } from "../MagnetUrlMaker";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import DownloadFile from "./DownloadFile";
import { FaWindowClose } from "react-icons/fa";
import { StreamShow } from "../StreamShow/StreamShow";

function DownloadSelection() {
  const { id } = useParams();
  const [movie, setMovie] = useState("");
  const [links, setLinks] = useState([]);
  const [frame, setFrame] = useState("");
  const [stream, setStream] = useState(false);
  const [frameClose, setFrameClose] = useState(false);
  // const ref = useRef(null);

  useEffect(() => {
    axios.get(tmdb_GetMovie(id)).then((response) => {
      // console.log(response.data);
      setMovie(response.data);

      axios_yts.get(yts_GetMovieLinks(movie.imdb_id)).then((response) => {
        if (response.data.status === "ok") {
          // console.log(response.data.data.movie.torrents)
          setLinks(response.data.data.movie.torrents);
        }
      });
    });
  }, [id, movie.imdb_id]);

  const magnetClick = (hash, name) => {
    window.location.href = magnet(hash, name);
  };

  const frameOpen = (url) => {
    setFrame(url);
    setFrameClose(true);
  };

  const frameClear = () => {
    setFrameClose(false);
    setFrame(null);
  };
  const downloadZip = (url) => {
    window.location.href = url;
  };

  const showStream = () => {
    setStream(true);
    // ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div
        className="download__wrapper"
        style={{
          backgroundImage: `URL(${
            movie ? tmdb_ImageUrl + movie.backdrop_path : ""
          })`,
        }}
      ></div>
      <div className="download__container">
        <div className="download__poster">
          <img src={movie ? tmdb_ImageUrl + movie.poster_path : ""} alt="" />
        </div>
        <div className="download__desc">
          <div>{getMovieTitle(movie)}</div>
          <div>{movie ? ISO6391.getName(movie.original_language) : "NA"}</div>
          <div>
            {movie ? Number(movie.vote_average.toFixed(1)) + "/10" : "NA"}
          </div>
          <div>{getCorrectOrderedDate(movie)}</div>
          <div>
            <div
              className="btn success-outline text-center mt-3rem"
              onClick={() => showStream()}
            >
              Watch Online
            </div>
          </div>
        </div>
      </div>

      {stream ? <StreamShow id={id} /> : ""}

      <div className="download__section">
        <div className="download__title">Download Section</div>
        {console.log(links)}
        {links
          ? links.map((torrent, index) => {
              return (
                <div className="download__link" key={index}>
                  <TfiAngleDoubleRight />
                  <span>{torrent ? torrent.type : "NA"}</span>
                  <div>{torrent ? torrent.video_codec : "NA"}</div>
                  <div>{torrent ? torrent.bit_depth + " bit" : "NA"}</div>
                  <div>{torrent ? torrent.quality : "NA"}</div>
                  <div>
                    <HiOutlineSpeakerWave />{" "}
                    {torrent ? torrent.audio_channels : "NA"}
                  </div>
                  <div>{torrent ? torrent.size : "NA"}</div>
                  {/* {console.log(magnet(torrent.hash, movie.name))} */}
                  <GiMagnet
                    className="warning"
                    onClick={() =>
                      magnetClick(torrent.hash, getMovieTitle(movie))
                    }
                  />
                  <PiFileZipFill
                    onClick={() => downloadZip(torrent.url)}
                    className="info"
                  />
                  <FiDownloadCloud
                    onClick={() =>
                      frameOpen(magnet(torrent.hash, getMovieTitle(movie)))
                    }
                    className="danger"
                  />
                </div>
              );
            })
          : console.log(links)}
      </div>
      <div className="download__file">
        {frameClose ? <FaWindowClose onClick={() => frameClear()} /> : ""}
        {frameClose ? <DownloadFile url={frame} /> : ""}
      </div>
    </>
  );
}

export default DownloadSelection;
