import React, {useEffect, useState} from 'react'
import './HomeRowMovies.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css';
import { Navigation, Pagination} from 'swiper/modules'
import {GoChevronRight,GoChevronLeft} from 'react-icons/go'
// axios 
import axios from '../../axios'
import {tmdb_trending} from '../../Constants/urls'
import {tmdb_ImageUrl} from '../../Constants/Constants'


function HomeRowMovies(props) {
  const [slider, setSlider] = useState([])
  useEffect(() => {
    axios.get(tmdb_trending).then((response)=>{
      setSlider(response.data.results)
    })
  }, [])
  return (
    <div className="row__wrapper">
       <div className="row-slider-controler">
          <div className={`button-prev-row prev-${props.name}`}>
              <GoChevronLeft/>
          </div>
          <div className={`button-next-row next-${props.name}`}>
              <GoChevronRight/>
          </div>
        </div>
        <div className="heading">{props.title}</div>
        <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={false}
        slidesPerView={8}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 7,
            spaceBetween: 0,
          },
        }}
        lazyPreloadPrevNext={3}
        navigation={{
            nextEl: `.next-${props.name}`,
            prevEl: `.prev-${props.name}`,
            clickable: true,
          }}
        loop={false}
        pagination={{ el: '.pagination', clickable: true }}
        modules={[Navigation, Pagination]}
        className={`${props.name}`}
      > 
        {slider.map((obj)=>{
          return(
            <SwiperSlide key={obj.id}>
              <img alt='hello' src={slider ? tmdb_ImageUrl+obj.poster_path : ''} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default HomeRowMovies