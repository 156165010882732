export const magnet = (hash,name)=>{
    const trackers = [
        'udp://glotorrents.pw:6969/announce',
        'udp://tracker.opentrackr.org:1337/announce',
        'udp://torrent.gresille.org:80/announce',
        'udp://tracker.openbittorrent.com:80',
        'udp://tracker.coppersurfer.tk:6969',
        'udp://tracker.leechers-paradise.org:6969',
        'udp://p4p.arenabg.ch:1337',
        'udp://tracker.internetwarriors.net:1337'

    ]

    var tr = ''
    trackers.forEach((obj)=>{
        tr += "&tr=" + obj;
    })

    return 'magnet:?xt=urn:btih:'+ hash +'&dn=[celluloid.in] - '+ name + tr
}